/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.cb-mobile-navigation {
  display: none;
}
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
div.sub1 {
  font-weight: normal;
  font-family: 'HelveticaNowProDisplayRegular', helvetica, sans-serif;
}
div.sub1 > .item:before {
  content: '|';
  display: inline-block;
  margin-right: 8px;
}
div.sub1 > .item .menu {
  display: inline-block;
  font-weight: normal;
  font-family: 'HelveticaNowProDisplayRegular', helvetica, sans-serif;
}
div.sub2 > .item {
  margin: 0 16px;
  position: relative;
}
@media (max-width: 1200px) {
  div.sub2 > .item {
    margin: 0 6px;
  }
}
div.sub2 > .item.init {
  margin-left: 0;
}
div.sub2 > .item.exit {
  margin-right: 0;
}
div.sub2 > .item:hover > .menu,
div.sub2 > .item:focus > .menu {
  color: #af1615;
}
div.sub2 > .item > .menu {
  padding-bottom: 8px;
  font-weight: normal;
  font-family: 'HelveticaNowProDisplayRegular', helvetica, sans-serif;
  color: #000;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: 1px;
}
div.sub2 > .item > .menu.path {
  color: #af1615;
}
div.sub3 {
  position: absolute;
  left: -12px;
  top: 100%;
  border: 1px solid #9d9d9c;
  background: #fff;
  display: none;
}
div.sub3 > .item {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #9d9d9c;
}
div.sub3 > .item.exit {
  border: none;
}
div.sub3 > .item:hover > .menu,
div.sub3 > .item:focus > .menu {
  color: #af1615;
}
div.sub3 > .item > .menu {
  white-space: nowrap;
  padding: 6px 12px;
  font-weight: normal;
  font-family: 'HelveticaNowProDisplayRegular', helvetica, sans-serif;
  color: #000;
  font-size: 15px;
  line-height: 1.2;
}
div.sub3 > .item > .menu.path {
  color: #af1615;
}
div.sub2 > .item.exit div.sub3,
div.sub2 > .item:nth-last-child(2) div.sub3 {
  left: auto;
  right: -12px;
}
div.sub2 > .item.exit div.sub3 div.sub4,
div.sub2 > .item:nth-last-child(2) div.sub3 div.sub4 {
  left: auto;
  right: 100%;
}
div.sub2 > .item:hover div.sub3 {
  display: block;
}
div.sub4 {
  position: absolute;
  left: 100%;
  top: -1px;
  border: 1px solid #9d9d9c;
  background: #fff;
  display: none;
}
div.sub4 > .item {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #9d9d9c;
}
div.sub4 > .item.exit {
  border: none;
}
div.sub4 > .item:hover > .menu,
div.sub4 > .item:focus > .menu {
  color: #af1615;
}
div.sub4 > .item > .menu {
  white-space: nowrap;
  padding: 6px 12px;
  font-weight: normal;
  font-family: 'HelveticaNowProDisplayRegular', helvetica, sans-serif;
  color: #000;
  font-size: 15px;
  line-height: 1.2;
}
div.sub4 > .item > .menu.path {
  color: #af1615;
}
div.sub3 > .item:hover div.sub4 {
  display: block;
}
#brands {
  float: left;
  width: 100%;
}
#brands .meta {
  float: left;
  display: block;
  overflow: hidden;
  text-indent: -9999px;
  margin: 0 28px;
  position: relative;
}
#brands .meta.service_kvadrat {
  width: 162px;
  height: 35px;
  margin-top: 23px;
  margin-left: 0;
  background: url(/images/kvadrat.png) no-repeat 0 0;
}
#brands .meta.service_benz {
  width: 109px;
  height: 71px;
  margin-top: 5px;
  background: url(/images/benz.png) no-repeat 0 0;
}
#brands .meta.service_montis {
  width: 179px;
  height: 34px;
  margin-top: 24px;
  background: url(/images/montis.png) no-repeat 0 0;
}
#brands .meta.service_sede {
  width: 120px;
  height: 81px;
  background: url(/images/sede.png) no-repeat 0 0;
}
#brands .meta.service_brune {
  width: 163px;
  height: 34px;
  margin-top: 24px;
  background: url(/images/brune.png) no-repeat 0 0;
}
#brands .meta.service_hutter {
  width: 143px;
  height: 66px;
  margin-top: 8px;
  margin-right: 0;
  background: url(/images/hutter.png) no-repeat 0 0;
}
/******* layout-large.less 2013-1-16 *******/
#footservices .cols {
  flex-flow: row wrap;
  justify-content: space-between;
}
#footservices .cols .col.col--right {
  margin-top: 0;
}
#serviceleft {
  display: block;
}
#serviceright {
  width: auto;
}
#mobile-navi,
.cb-toggle.open-navi {
  display: none;
}
#home {
  transition: all 0.4s;
  position: relative;
  width: 241px;
  overflow: hidden;
}
#home .logo {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 241px;
  height: 138px;
}
.cb-scroll-triggered--active #home {
  height: 68px;
  margin: 11px 0;
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: #fff;
}
.section--header.cb-scroll-triggered--active {
  box-shadow: 0 0 4px rgba(100, 100, 100, 0.6);
}
.section--multimood {
  margin-top: 180px;
}
.cb-layout2 .section--content,
.cb-layout4 .section--content,
.cb-layout5 .section--content {
  margin-top: 180px;
}
.navipart--1 {
  transition: all 0.4s;
}
.cb-scroll-triggered--active .navipart--1 {
  margin-top: -39px;
}
.navipart--2 {
  margin-top: 28px;
  transition: all 0.4s;
}
.cb-scroll-triggered--active .navipart--2 {
  margin-top: 36px;
}
.search {
  transition: all 0.4s;
  transform-origin: 50% 0;
}
.cb-scroll-triggered--active .search {
  transition: all 0.2s;
  opacity: 0;
  transform: scale(0);
  pointer-events: none;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.52284264%;
  margin-left: 1.52284264%;
}
.area .part,
.area > .grid table {
  margin-right: 1.52284264%;
  margin-left: 1.52284264%;
  width: 96.95431472%;
}
.area .tiny {
  width: 46.95431472%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 3.04568528%;
  margin-left: 3.04568528%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 93.90862944%;
}
.area > .slim .tiny {
  width: 43.90862944%;
}
.north {
  width: 100%;
}
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north h2,
.north .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.north .part,
.north > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north > .slim .part,
.north > .slim.grid table {
  width: 100%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 0.76530612%;
  margin-left: 0.76530612%;
}
.base .part,
.base > .grid table {
  margin-right: 0.76530612%;
  margin-left: 0.76530612%;
  width: 98.46938776%;
}
.base .tiny {
  width: 48.46938776%;
}
.base > .slim {
  width: 16.66666667%;
}
.base > .slim h2,
.base > .slim .foot,
.base > .slim .part,
.base > .slim.grid table {
  margin-right: 4.59183673%;
  margin-left: 4.59183673%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 90.81632653%;
}
.base > .slim .tiny {
  width: 90.81632653%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 1.53846154%;
  margin-left: 1.53846154%;
  width: 96.92307692%;
}
.side h2,
.side .foot {
  margin-right: 1.53846154%;
  margin-left: 1.53846154%;
}
.side .part,
.side > .grid table {
  margin-right: 1.53846154%;
  margin-left: 1.53846154%;
  width: 96.92307692%;
}
.side > .slim .part,
.side > .slim.grid table {
  width: 96.92307692%;
}
.farwest {
  width: 100%;
}
.farwest > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.farwest h2,
.farwest .foot {
  margin-right: 0.9202454%;
  margin-left: 0.9202454%;
}
.farwest .part,
.farwest > .grid table {
  margin-right: 0.9202454%;
  margin-left: 0.9202454%;
  width: 98.1595092%;
}
.farwest .tiny {
  width: 48.1595092%;
}
.farwest > .slim {
  width: 50%;
}
.farwest > .slim h2,
.farwest > .slim .foot,
.farwest > .slim .part,
.farwest > .slim.grid table {
  margin-right: 1.8404908%;
  margin-left: 1.8404908%;
}
.farwest > .slim .part,
.farwest > .slim.grid table {
  width: 96.3190184%;
}
.farwest > .slim .tiny {
  width: 96.3190184%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.part.auth.tile .text,
.part.auth.tile .password {
  width: 63%;
}
.part.payp.tile,
.part.payp.tile .papc {
  position: static;
}
.part.payp.tile .papc {
  margin: 0;
  padding: 0;
}
.part.payp.tile input.numb {
  position: static;
  float: left;
}
.part.payp.tile .name {
  float: left;
  width: 60%;
}
.slim .tiny.auth.tile .password {
  width: 100%;
}
.slim .tiny.payp.tile,
.slim .tiny.payp.tile .papc {
  position: relative;
}
.slim .tiny.payp.tile .papc {
  margin-bottom: -2em;
  padding-bottom: 2em;
}
.slim .tiny.payp.tile input.numb {
  position: absolute;
  bottom: 0.4em;
}
.slim .tiny.payp.tile input.numb.grow {
  position: absolute !important;
}
.slim .tiny.payp.tile .name {
  float: none;
  width: 100%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.cb-layout1 .main,
.cb-layout4 .main {
  margin-left: -0.9375%;
  width: 101.875%;
}
.cb-layout1 .main div.wide:first-child div.part,
.cb-layout4 .main div.wide:first-child div.part,
.cb-layout1 .main div.wide:first-child div.head h2,
.cb-layout4 .main div.wide:first-child div.head h2 {
  margin-left: 0.9202454%;
  margin-right: 0.9202454%;
}
.cb-layout1 .main div.wide:first-child div.part.tall,
.cb-layout4 .main div.wide:first-child div.part.tall,
.cb-layout1 .main div.wide:first-child div.head h2.tall,
.cb-layout4 .main div.wide:first-child div.head h2.tall {
  width: 98.1595092%;
}
.cb-layout1 .main div.wide:first-child div.part.tiny,
.cb-layout4 .main div.wide:first-child div.part.tiny,
.cb-layout1 .main div.wide:first-child div.head h2.tiny,
.cb-layout4 .main div.wide:first-child div.head h2.tiny {
  width: 48.1595092%;
}
.cb-layout1 .main div.wide,
.cb-layout4 .main div.wide {
  width: 66.66666667%;
}
.cb-layout1 .main div.wide div.part,
.cb-layout4 .main div.wide div.part {
  margin-left: 1.3799448%;
  margin-right: 1.3799448%;
}
.cb-layout1 .main div.wide div.part.tall,
.cb-layout4 .main div.wide div.part.tall {
  width: 97.2401104%;
}
.cb-layout1 .main div.wide div.part.tiny,
.cb-layout4 .main div.wide div.part.tiny {
  width: 47.19411224%;
}
.cb-layout1 .main div.slim,
.cb-layout4 .main div.slim {
  width: 33.31288344%;
}
.cb-layout1 .main div.slim div.part,
.cb-layout4 .main div.slim div.part {
  margin-left: 2.76243094%;
  margin-right: 2.76243094%;
}
.cb-layout1 .main div.slim div.part.tall,
.cb-layout4 .main div.slim div.part.tall {
  width: 94.47513812%;
}
.cb-layout1 .main div.slim div.part.tiny,
.cb-layout4 .main div.slim div.part.tiny {
  width: 44.38305709%;
}
.base {
  margin-left: -0.77720207%;
  width: 101.55440415%;
}
.side {
  float: right;
  width: 33.67875648%;
  margin-right: -0.51813472%;
}
.side div.unit div.part {
  width: 100%;
  margin: 0;
}
.side div.unit div.head h2 {
  margin: 0;
}
div.north {
  width: 100%;
  margin: 0;
}
div.farwest {
  width: 101.875%;
  margin-left: -0.9375%;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .side {
    margin-right: -0.6044905%;
  }
}
.cb-layout2 .main .flat div.text p.norm a,
.cb-layout3 .main .flat div.text p.norm a,
.area.areaTen .flat div.text p.norm a {
  font-size: 28px;
}
.cb-layout2 .main .flat div.text p.norm a:first-line,
.cb-layout3 .main .flat div.text p.norm a:first-line,
.area.areaTen .flat div.text p.norm a:first-line {
  font-size: 46px;
}
.area.areaTen div.unit div.head h2 {
  font-size: 28px;
}
.area.areaTen div.unit div.head h2 strong {
  font-size: 46px;
}
.cb-layout2 .main .flat div.text p.norm a:first-line,
.cb-layout3 .main .flat div.text p.norm a:first-line,
.area.areaTen .flat div.text p.norm a:first-line {
  font-size: 46px;
}
@media only screen and (min-width: 1246px) {
  .area.areaTen div.unit div.head h2 {
    font-size: 34px;
  }
  .area.areaTen div.unit div.head h2 strong {
    font-size: 55px;
  }
  .cb-layout2 .main .flat,
  .cb-layout3 .main .flat,
  .area.areaTen .flat {
    background: #000;
  }
  .cb-layout2 .main .flat div.pict,
  .cb-layout3 .main .flat div.pict,
  .area.areaTen .flat div.pict {
    -o-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
  }
  #view.cb-layout2 .main .flat div.text p.norm a,
  #view.cb-layout3 .main .flat div.text p.norm a,
  #view.area.areaTen .flat div.text p.norm a {
    font-size: 34px;
  }
  #view.cb-layout2 .main .flat div.text p.norm a:first-line,
  #view.cb-layout3 .main .flat div.text p.norm a:first-line,
  #view.area.areaTen .flat div.text p.norm a:first-line {
    font-size: 55px;
  }
}
.cb-layout2 .main .flat,
.cb-layout3 .main .flat {
  margin-left: 0.9202454%;
  margin-right: 0.9202454%;
}
.cb-layout2 .main .flat.wide,
.cb-layout3 .main .flat.wide {
  width: 98.1595092%;
}
.cb-layout2 .main .flat.slim,
.cb-layout3 .main .flat.slim {
  width: 48.1595092%;
}
div.farwest div.seam.wide {
  width: 66.68711656%;
}
div.farwest div.seam.wide .part {
  width: 97.2401104%;
  margin-left: 1.3799448%;
  margin-right: 1.3799448%;
}
div.farwest div.seam.slim {
  width: 33.31288344%;
}
div.farwest div.seam.slim .part {
  width: 94.47513812%;
  margin-left: 2.76243094%;
  margin-right: 2.76243094%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10% !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -825px;
  width: 1650px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #af1615;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #af1615;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #811010;
}
.mese .same {
  background: none;
}
/******* module-shop-large.less 2013-1-16 *******/
.cb-shop-tabs > li {
  display: inline-block;
  margin-bottom: -1px;
}
.cb-shop-tab {
  margin-right: 0.3em;
  border-bottom-width: 1px;
  border-radius: 4px 4px 0 0;
}
.cb-shop-tab.selected {
  border-bottom-color: #f5f5f5;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  width: 32%;
}
#disp.shop textarea {
  width: 67%;
}
#disp.shop .file,
#disp.shop .text,
#disp.shop select {
  width: 67%;
}
#disp.shop input.zip {
  clear: none;
  width: 10%;
}
#disp.shop input.city,
#disp.shop .cb-shop-currency > select,
#disp.shop .cb-shop-countries > select,
#disp.shop .cb-shop-regions > select {
  width: 55%;
}
#disp.shop input.city {
  width: 56%;
}
.cb-shop-currency > select + a {
  width: 6%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a {
  clear: none;
  margin-bottom: 0.5em;
}
.cb-shipping-range-label-input,
.cb-shipping-range-value-input {
  width: 100% !important;
}
#disp .cb-shop-country > select {
  width: 67%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart .ship,
.cb-shoppingcart fieldset.tiny {
  float: left;
  width: 49%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart fieldset.tiny {
  margin-right: 2%;
}
/*# sourceMappingURL=./screen-large.css.map */